











import { Vue, Component } from 'vue-property-decorator';
import IconMessage from '@/shared/resources/components/IconMessage.vue';
import Divider from '@/shared/resources/components/Divider.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';

@Component({
  components: {
    Button,
    Divider,
    IconMessage,
  },
})
export default class CartOrderMBankPaynowErrorView extends Vue {

}
